<template>
  <CForm @submit="login">
<!--    <h5 class="text-center">{{ $t('login') }}</h5>-->
    <br>
    <p class="text-muted text-center">{{ $t('sign_in_to_your_account') }}</p>
    <CAlert color="danger" v-if="!_.isEmpty(error)" closeButton>
      {{ error }}
    </CAlert>
    <CInput
        :placeholder="$t('email')"
        autocomplete="email"
        v-model="email"
        @keydown="resetErrors"
        :invalid-feedback="_.has(errors, 'email') ? errors.email : ''"
        :is-valid="_.has(errors, 'email') ? false : null"
    >
      <template #prepend-content><CIcon name="cil-user"/></template>
    </CInput>
    <div class="wy-input-group wy-no-end-border">
      <CInput
          :placeholder="$t('password')"
          :type="passwordIsVisible ? 'text' : 'password'"
          autocomplete="curent-password"
          v-model="password"
          @keydown="resetErrors"
          :invalid-feedback="_.has(errors, 'password') ? errors.password : ''"
          :is-valid="_.has(errors, 'password') ? false : null"
      >
        <template #prepend-content><CIcon name="cil-lock-locked"/></template>
        <template #append>
          <div class="wy-password-visible-container"
               :class="{ 'wy-has-error': _.has(errors, 'password') }"
               @click="passwordIsVisible = !passwordIsVisible">
            <i :class="{ 'wy-icon-visibility-open': passwordIsVisible, 'wy-icon-visibility-close': !passwordIsVisible }" style="font-size: 22px;"></i>
          </div>
        </template>
      </CInput>
    </div>
    <CRow class="flex-row align-items-center">
      <CCol col="5" class="text-left">
        <CButton type="submit" color="red" class="px-4">{{ $t('login_submit') }}</CButton>
      </CCol>
      <CCol col="7" class="text-right">
<!--        <router-link :to="{ name: 'ForgetPassword', params: { languageCode: $store.state.languageCode } }">{{ $t('forgot_password') }}</router-link>-->
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import {login} from "../../api/auth";

export default {
  name: 'Login',
  data() {
    return {
      email: this.$config.debug ? 'ayman@test.test' : '',
      password: this.$config.debug ? '911911' : '',
      errors: {},
      error: '',
      passwordIsVisible: false,
    };
  },
  watch: {
    '$store.state.languageCode': function () {
      this.error = ''
    }
  },
  methods: {
    resetErrors() {
      this.errors = {}
      this.error = ''
    },
    validate(callback) {
      let hasErrors = false;
      this.resetErrors()

      if (this.email === '') {
        this.errors.email = this.$t('field_is_required', { field: this.$t('email') });
        hasErrors = true
      }

      if (this.password === '') {
        this.errors.password = this.$t('field_is_required_IN_ARABIC_FEMALE', { field: this.$t('password') });
        hasErrors = true
      }

      this.$forceUpdate()
      if (!hasErrors) {
        callback()
      }
    },
    isValidEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    login(event) {
      if (this._.isFunction(event.preventDefault)) {
        event.preventDefault();
      }
      this.validate(() => {
        login(this, this.email, this.password, (user) => {
          this.$store.commit('setUser', user);
          if (user['role'] === 'system_admin') {
            this.$router.push({name: 'UserHome'});
          } else {
            if (!this._.isEmpty(user.gas_stations)) {
              this.$router.push({name: 'new_worker_invoice'});
            } else {
              this.error = this.$t('you_do_not_have_any_permission_to_using_this_system');
            }
          }
        }, (error) => {
          this.error = error.message;
        });
      })
    },
  },
}
</script>
